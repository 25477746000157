import React, { Component } from "react"
import styles from "./careerForm.module.scss"
import Dropzone from "react-dropzone"
import { Link } from "gatsby"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faTimes } from "@fortawesome/free-solid-svg-icons"
import SnackbarWrapper from "../SnackBarWrapper/SnackBarWrapper"
import clear from "../../images/icons/clear.png";

class CareerForm extends Component {
  state = {
    name: "",
    email: "",
    phone: "",
    docs: [],
    job: "",
    applicant_agreement: false,
    details_agreement: false,
    privacy: false,
    activeTab: 0,
    success: false,
    checkError: false,
    errorMessage: "",
    error: false,
    emailError: false,
    nameError: false,
    phoneError: false,
    buttonState: false,
  }

  emailValid = value => {
    var emailRegex = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i
    if (!emailRegex.test(value)) {
      return false
    } else {
      return true
    }
  }

  phoneValid = value => {
    // eslint-disable-next-line
    var phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
    if (!phoneRegex.test(value)) {
      return false
    } else {
      return true
    }
  }

  tabZero = e => {
    this.setState({
      activeTab: 0,
    })
  }

  tabOne = e => {
    e.preventDefault()
    if (!this.emailValid(this.state.email)) {
      this.setState({
        emailError: true,
      })
      return
    } else if (!this.phoneValid(this.state.phone)) {
      this.setState({
        phoneError: true,
      })
      return
    } else if (!this.state.name) {
      this.setState({
        nameError: true,
      })
      return
    } else {
      this.setState({
        activeTab: 1,
        emailError: false,
        nameError: false,
        phoneError: false,
      })
      return
    }
  }

  tabTwo = e => {
    if (this.state.docs.length === 0) {
      alert("Please Upload a document")
      return
    } else {
      this.setState({
        activeTab: 2,
      })
    }
  }

  acceptedFiles = files => {
    this.setState(prevState => ({
      docs: prevState.docs.concat(files),
    }))
  }

  removeFiles = i => e => {
    let deleteFile = this.state.docs[i]
    this.setState(prevState => ({
      docs: prevState.docs.filter(doc => doc !== deleteFile),
    }))
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  applicantCheck = e => {
    this.setState(prevState => ({
      applicant_agreement: !prevState.applicant_agreement,
    }))
  }

  detailsCheck = e => {
    this.setState(prevState => ({
      details_agreement: !prevState.details_agreement,
    }))
  }

  privacyCheck = e => {
    this.setState(prevState => ({
      privacy: !prevState.privacy,
    }))
  }

  onSubmit = e => {
    e.preventDefault()
    let check = this.state.privacy && this.state.applicant_agreement && this.state.details_agreement
    if (!check) {
      alert("Please Check All applicant agreement")
    } else {
      this.setState({
        buttonState: true,
      });
      const { name, email, phone } = this.state
      const formData = new FormData()
      for (const key of Object.keys(this.state.docs)) {
        formData.append("docs", this.state.docs[key])
      }
      formData.append("name", name)
      formData.append("email", email)
      formData.append("phone", phone)
      formData.append("job", this.props.job)
      const url = `https://iqube-sendgrid.herokuapp.com/career`
      fetch(url, {
        method: "POST",
        body: formData,
      })
        .then(res => {
          if (res.ok) {
            this.setState({
              success: true,
              buttonState: false
            })
          } else if (!res.ok) {
            this.setState({
              error: true,
            })
          }
        })
        .catch(err => console.log(err))
    }
  }

  closeSuccess = () => {
    this.props.hideModal()
    this.setState({
      name: "",
      email: "",
      phone: "",
      applicant_agreement: false,
      details_agreement: false,
      privacy: false,
      files: [],
      activeTab: 0,
      success: true,
      checkError: false,
      errorMessage: "",
      error: false,
    })
  }

  clearMessage = () => {
    this.setState({
      success: false,
      error: false,
      checkError: false,
    })
  }

  render() {
    let message
    if (this.state.error) {
      message = (
        <SnackbarWrapper variant="error" message="Error, please try again" />
      )
    } else if (this.state.success) {
      message = (
        <SnackbarWrapper
          variant="success"
          message="Please check all Applicant Agreement"
          onClose={this.clearMessage}
        />
      )
    }

    return (
      <div className={styles.modal}>
        <div className={styles.mainModal}>
          <div className={styles.head}>
            <h4>The Journey Begins Here.</h4>
            <p>
              Fill in the details below to begin the application process for
              your preferred position
            </p>
            <p>{this.props.job}</p>
          </div>

          {this.state.success ? (
            <div className={styles.success}>
              <p>
                Your application for the role of {this.props.job} has been submitted successfully, Please look out for a response from us.
              </p>
              <button onClick={this.closeSuccess} className={styles.button}>Close</button>
            </div>
          ) : (
            <>
              <div className={styles.tab}>
                <span
                  onClick={this.tabZero}
                  className={this.state.activeTab === 0 ? styles.active : ""}
                >
                  1. Basic Information
                </span>
                <span
                  onClick={this.tabOne}
                  className={this.state.activeTab === 1 ? styles.active : ""}
                >
                  2. Application Document
                </span>
                <span
                  onClick={this.tabTwo}
                  className={this.state.activeTab === 2 ? styles.active : ""}
                >
                  3. Final Declaration
                </span>
              </div>
              <form
                className={styles.form}
                method="post"
                encType="multipart/form-data"
              >
                {this.state.activeTab === 0 ? (
                  <div className={styles.info}>
                    <div className={styles.formcontrol}>
                      <label htmlFor="Full Name">Full name</label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        required
                        onChange={this.handleChange}
                        value={this.state.name}
                      />
                      {this.state.nameError ? (
                        <span>Please enter your name</span>
                      ) : null}
                    </div>

                    <div className={styles.phoneAndEmailInput}>
                      <div className={styles.formcontrol}>
                        <label htmlFor="Phone Number">Phone Number</label>
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          required
                          onChange={this.handleChange}
                          value={this.state.phone}
                        />
                        {this.state.phoneError ? (
                          <span>Invalid Phone Number</span>
                        ) : null}
                      </div>
                      <div className={styles.formcontrol}>
                        <label htmlFor="Email Address">Email Address</label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          required
                          onChange={this.handleChange}
                          value={this.state.email}
                        />
                        {this.state.emailError ? (
                          <span>Invalid Email Address</span>
                        ) : null}
                      </div>
                    </div>

                    <button
                      className={styles.button}
                      type="button"
                      onClick={this.tabOne}
                    >
                      Continue
                    </button>
                  </div>
                ) : null}
                {this.state.activeTab === 1 ? (
                  <div className={styles.upload}>
                    <Dropzone
                      onDrop={this.acceptedFiles}
                      multiple={true}
                      accept=".doc, .pdf, .txt"
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div
                            {...getRootProps({ className: styles.dropzone })}
                          >
                            <input {...getInputProps()} name="docs" />
                            <p>
                              Drag 'n' drop some files here, or click to select
                              files
                            </p>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    <p>
                      Kindly upload a copy of your resume and cover letter by
                      clicking on the area above or drag and drop file. Files should be in
                      .DOCX, .PDF or .TXT format
                    </p>
                    <div className={styles.file}>
                      {this.state.docs.length > 0?<p>Files Uploaded</p>:null}
                      {this.state.docs.map((file, i) => {
                        return (
                          <div key={file.name} className={styles.row}>
                          <button type="button" onClick={this.removeFiles(i)} className={styles.removeBtn}>
                              Remove
                            </button>
                            <span className={styles.fileName}>{file.path}</span>
                            
                          </div>
                        )
                      })}
                    </div>
                    <button
                      className={styles.button}
                      type="button"
                      onClick={this.tabTwo}
                    >
                      Continue
                    </button>
                  </div>
                ) : null}

                {this.state.activeTab === 2 ? (
                  <div className={styles.declaration}>
                    <h5>Applicant Agreement</h5>
                    <p>
                      By checking the boxes below, you verify that the
                      Information you are about to submit is genuine.
                    </p>
                    <div className={styles.check}>
                      <div className={styles.customInput}>
                        <input
                          type="checkbox"
                          checked={this.state.applicant_agreement}
                          name="applicant_agreement"
                          onChange={this.applicantCheck}
                        />
                        <span className={styles.checkmark}></span>
                      </div>

                      <span>
                        I've read and agreed to the Applicant Agreement
                      </span>
                    </div>
                    <div className={styles.check}>
                      <div className={styles.customInput}>
                        <input
                          type="checkbox"
                          checked={this.state.details_agreement}
                          name="details_agreement"
                          onChange={this.detailsCheck}
                        />
                        <span className={styles.checkmark}></span>
                      </div>

                      <span>
                        I agree that the details put in for this application is
                        mine and authentic
                      </span>
                    </div>
                    <div className={styles.check}>
                      <input
                        type="checkbox"
                        checked={this.state.privacy}
                        name="privacy"
                        onChange={this.privacyCheck}
                      />
                      <span className={styles.checkmark}></span>

                      <span>
                        I agree to the <a href="/privacy" target="_blank">Privacy terms</a>
                      </span>
                    </div>
                    <button
                      className={styles.button}
                      type="button"
                      onClick={this.onSubmit}
                      disabled={this.state.buttonState}
                    >
                      {this.state.buttonState ? "Submitting" : "Finish"}
                    </button>
                  </div>
                ) : null}
              </form>{" "}
            </>
          )}

          <button onClick={this.closeSuccess} className={styles.close}>
            <img src={clear} alt="Clear Modal"/>
          </button>
        </div>
      </div>
    )
    // <FontAwesomeIcon className={styles.closeIcon} icon={faTimes} />

  }
}

export default CareerForm
