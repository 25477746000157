import React from "react"
import Layout from "../components/layout"
import CareerOpening from '../components/CareerOpening/CareerOpening';
import CareerHead from "../components/CareerHead/CareerHead";
import SEO from "../components/seo";



const Careers = () => {
 return (
  <Layout>
   <SEO
    title="iQubeLabs | Careers"
    description="We are passionate about nurturing talents to become super professionals. We encourage continuous improvement and applied innovations."
    />
    <CareerHead />
    <CareerOpening />
  </Layout>
 )
}

export default Careers