import React, { Component } from 'react'
import styles from './careerHead.module.scss'
import Image from './../../images/creativityBulbs.png'
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';


class CareerHead extends Component {
  render() {
    return (
      <div className={styles.careerHead}>
        <div className={styles.container}>
           <div className={styles.content}>
            <div className={styles.text}>
            <Fade left>
              <h1>
                Careers At iQubeLabs
              </h1>
              <h2>
                We are passionate about nurturing talents to become super professionals. We encourage continuous improvement and applied innovations.
              </h2>
            </Fade>
            </div>
            <Zoom>

            <div className={styles.image}>
              <img src={Image} alt="brain" />
            </div>
            </Zoom>
        </div>
      </div>
      </div>
    )
  }
}

export default CareerHead
