import React from 'react';
import CareerItem from './../CareerItem/CareerItem'
import {
  graphql,
  StaticQuery
} from 'gatsby';


export default () => (
  <StaticQuery
    query={graphql`
      query Careers {
        allContentfulCareers(
          sort:{
            fields: createdAt,
            order: DESC
          }
        ) {
          edges {
            node {
              category
              responsibilities {
                childMarkdownRemark {
                  html
                }
              }
              requirement {
                childMarkdownRemark {
                  html
                }
              }
              title
              description {
                description
              }
            }
          }
        }
      }
    `}
    render={data => (
      <CareerItem 
        data={data}
      />
    )}
  />
)

