import React, { Component } from 'react';
import CareerDropDown from '../CareerDropDown/CareerDropDown';
import styles from './careeritem.module.scss';


class CareerItem extends Component {
 
  render () {
    const careers = this.props.data.allContentfulCareers.edges
    const contractCareer = careers.filter(career => career.node.category === "Contract");
    const fullTimeCareer = careers.filter(career => career.node.category === "Full Time");
    const internshipCareer = careers.filter(career => career.node.category === "Internship");

    return (
      <div className={styles.container}>
        <h3>
         No Current Job Opening
        </h3>


      {/* Instruction: When there is a job opening, uncomment the code below!!!!! and change the h3 to 'Current Job Openings' */}

      {/* {fullTimeCareer.length !== 0 && <div className={styles.careerItem}>
        <h5>
          Full Time
        </h5>
        {
          careers.map((careerItem, index) =>  <CareerDropDown
            key={index} 
            title={careerItem.node.title}
            description={careerItem.node.description.description}
            responsibilities={careerItem.node.responsibilities.childMarkdownRemark.html}
            requirements={careerItem.node.requirement.childMarkdownRemark.html}
            career={fullTimeCareer}
            showModal={this.showModal}
            id={careerItem.node.id}
          />
        )
         
        }
      </div>
      }
      {internshipCareer.length !== 0 && <div className={styles.careerItem}>
        <h5>
          Internship
        </h5>
        {
          careers.map((careerItem, index) =>  <CareerDropDown
            key={index} 
            title={careerItem.node.title}
            description={careerItem.node.description.description}
            responsibilities={careerItem.node.responsibilities.childMarkdownRemark.html}
            requirements={careerItem.node.requirement.childMarkdownRemark.html}
            career={internshipCareer}
            showModal={this.showModal}
            id={careerItem.node.id}
          />
        )
         
        }
      </div>}
      {contractCareer.length !== 0 && <div className={styles.careerItem}>
        <h5>
          Contract
        </h5>
        {
          careers.map((careerItem, index) =>  <CareerDropDown
            key={index} 
            title={careerItem.node.title}
            description={careerItem.node.description.description}
            responsibilities={careerItem.node.responsibilities.childMarkdownRemark.html}
            requirements={careerItem.node.requirement.childMarkdownRemark.html}
            career={contractCareer}
            showModal={this.showModal}
            id={careerItem.node.id}
          />
        )
         
        }
      </div>}  */}
    </div>
    )
  }

}

export default CareerItem
